.rowTitle {
  margin-top: 15px;
}

.rowDescription {
  margin-top: 30px;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.labelTitle {
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-dark-blue, #2A3842);

  &:after {
    content: '*';
    color: var(--secondary, #f00);
  }
}

.labelDescription {
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-dark-blue, #2A3842);
}

.gptButtonContainer {
  display: flex;
  gap: 10px;

  div:first-child button:disabled {
    background: linear-gradient(90deg, #015cd5 0%, #016af8 100%);
    opacity: 0.5;
    color: #fff;
  }

  div:last-child button{
    color: var(--primary-dark-blue, #2A3842);
  }

  div:last-child button:disabled {
    background: #fff;
    opacity: 0.5;
  }
}

.gptContainer {
  margin-top: 12px;
}
