.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.checked {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>');
}
