.listItem {
  background-color: #f1f7ff;
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    .sliderControls {
      opacity: 1;
    }
  }

  @media (min-width: 600px) {
    border-radius: 20px;
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  // max-width: 995px;
}

.itemImg {
  display: block;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 14px 14px 0 0;

    @media (min-width: 600px) {
      max-height: 350px;
    }
  }
}

.planImg {
  display: block;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    width: 137px;
    height: auto;
    object-fit: cover;

    // @media (min-width: 600px) {
    //   max-height: 350px;
    // }
  }
}

.sliderControls {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  right: 18px;
  display: flex;
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
  }
}

.itemImgInfo {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 20px;
}


.itemImgIcons {
  display: flex;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.itemImgIconsImages {
  display: flex;
  align-items: center;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
}

.locationIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #1650FF
}

@media (max-width: 600px) {
  .locationIcon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
  }
}

.list {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 20px;
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}

.pageName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;

  @media (max-width: 580px) { 
    align-items: flex-start;
    gap: 20px;
  }
}
