.container {
  max-width: 995px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerDistress {
  max-width: 995px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 20px;
  align-items: flex-start;

  @media (min-width: 600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.rowSeller {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  align-items: flex-start;

  @media (min-width: 600px) {
    gap: 12px 20px;
    grid-template-columns: repeat(7, 1fr);
  }
}

.rowDistress {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  align-items: flex-start;

  @media (min-width: 600px) {
    gap: 12px 20px;
    grid-template-columns: repeat(7, 1fr);
  }
}

.currency {
  display: flex;
  gap: 12px 20px;
}

.allHandover {
  display: flex;
  gap: 12px 20px;
  
  @media (max-width: 600px) {
    margin-top: -30px;
  }
}

.onlyHandover {
  @media (max-width: 600px) {
    margin-top: -30px;
  }
}

.singleItem {
  grid-column: span 5;
}

.price {
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 4;
  }
}

.priceResale {
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 2;
  }
}

.priceDistress {
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 3;
  }
}

.priceSelling {
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 2;
  }
}

.rowFee {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 20px;
  margin-top: 12px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px 20px;
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 600px) {
  .bgSeparator {
    border-left: 1px solid #E9EEF1;
    border-right: 1px solid #E9EEF1;
    padding: 0 22px;
  }
}

.topUpLabel {
  display: flex;
  align-items: center;
  color: #2A3842;

  span {
    color: #1650FF;
  }

  label {
    margin-right: 0;
  }
}


.rentFrequencyContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.periodContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  // border: 1px solid #1650FF;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  overflow: hidden;
  padding: 10px;
  gap: 10px;

  @media (min-width: 600px) {
    width: 235px;
    min-width: 235px;
    padding: 15px;
  }
}

.colorPeriodContainer {
  border: 1px solid #1650FF;
}

.grayPeriodContainer {
  border: 1px solid #E9EEF1;
}

.errorPeriodContainer {
  border: 1px solid #ff0000;
}

.name {
  font-size: 18px;
  font-weight: 600;
  padding: 0 7px;
  box-sizing: border-box;
  text-align: center;
  color: #1650FF;
}

.periodPrice {
  grid-column: span 1;
}

.radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.label {
  margin: 20px 0 7px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  span {
    color: #ff0000;
  }
}

.error {
  font-size: 12px;
  text-align: right;
  color: #ff0000;
  margin-top: 3px;
}

.frequencyContainer {
  margin-bottom: 20px;
}

.handover {
  width: 380px;
  padding: 0 20px;
  margin-top: -10px;
}

.handoverHeading {
  font-size: 15px;
  font-weight: 700;
  margin: 20px 0 0 0;
  display: block;
}

button.selectButtons {
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  text-transform: none;
  color: #2a3842;
  background: transparent;

  &:hover {
    background: #eeeeee;
    box-shadow: none;
  }

  &.selectButtonsActive {
    background: #c5dfff;
  }

  &.propertyKindButton {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 10px;

    @media (min-width: 600px) {
      width: 190px;
    }
  }

  &.bedsBathsButton {
    max-width: 80px;
    width: 100%;
    padding: 2px 2px;

    @media (min-width: 600px) {
      padding: 2px 2px;
    }
    @media (min-width: 900px) {
      margin-right: 2px;
      padding: 2px 2px;
    }
  }
}

.multilistingContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.button {
  display: flex;
  width: 117px;
  height: 40px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.buttonActive {
  border: 2px solid #1650FF;

  span {
    font-size: 14px;
    font-weight: 600;
    color:#1650FF;
   }
}

.buttonPassive {
  border: 1px solid #2A3842;

  span {
    font-size: 14px;
    color:#2A3842;
   }
}

