.listItem {
  width: 100%;
  max-width: 370px;

  background-color: #f1f7ff;
  border-radius: 14px;
  transition: 0.3s;

  &:hover {
    box-shadow: 2px 1px 16px 0 #00000024;

    .sliderControls {
      opacity: 1;
    }
    .itemUpdatedDate {
      opacity: 0.5;
    }
    .itemTransactionDate {
      opacity: 0;
    }
  }

  @media (min-width: 600px) {
    max-width: 482px;
  }
}

.itemImg {
  display: block;
  position: relative;

  img {
    display: block;
    height: 238px;
    width: 100%;
    border-radius: 14px 14px 0 0;
    object-fit: cover;
  }
}

.sliderControls {
  transition: 0.3s;
  opacity: 0;
  position: absolute;
  top: 100px;
  left: 18px;
  right: 18px;
  display: flex;
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
  }
}

.itemImgInfo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 18px;
}

.itemUpdatedDate {
  transition: 0.3s;
  opacity: 0;
  position: absolute;
  top: 18px;
  left: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  height: 32px;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: #2a3842;

  img {
    width: 20px;
    height: 20px;
  }
}

.itemTransactionDate {
  // transition: 0.3s;
  // opacity: 0;
  position: absolute;
  top: 18px;
  left: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #2a3842;
  height: 32px;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.itemImgIcons {
  position: absolute;
  top: 18;
  right: 18px;
  display: flex;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.itemImgIconsImages {
  display: flex;
  align-items: center;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
}

.itemContent {
  padding: 14px;
  max-width: 400px;

  @media (min-width: 900px) {
    padding: 16px 22px;
    max-width: 100%;
  }
}

a.itemName {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #2a3842;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 900px) {
    font-size: 24px;
  }
}

.itemPriceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-top: 10px;
  font-size: 15px;

  @media (min-width: 900px) {
    font-size: 17px;
  }
}

.itemPrice {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2a3842;

  span {
    color: #1650ff;
  }
}

.itemLabel {
  display: inline-block;
  border: 1px solid #1650ff;
  border-radius: 35px;
  padding: 3px 13px;
  font-size: 12px;
  font-weight: 600;
  color: #1650ff;
  text-transform: capitalize;
}

.itemFee {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  color: #1650ff;
}

.itemAddress {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 15px;
  font-weight: 500;
  color: #2a3842;
  white-space: nowrap;
  overflow: hidden;

  font-size: 15px;

  @media (min-width: 900px) {
    font-size: 16px;
  }
}

.itemParams {
  display: flex;
  gap: 12px;
  margin-top: 9px;
  font-size: 15px;

  @media (min-width: 600px) {
    gap: 26px;
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 8px;
  }
}

.itemParamsSeparator {
  width: 1px;
  height: 24px;
  background-color: #2a384233;
}

.itemLinks {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #2a384220;
  margin-top: 20px;
  padding-top: 10px;
  gap: 15px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    color: #1650ff;
    width: 50%;
    overflow: hidden;

    div {
      font-family: 'Poppins', sans-serif;
      width: 24px;
      height: 24px;
      font-size: 12px;
      font-weight: 500;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.text {
  filter: blur(0px);
}
.blurText {
  filter: blur(5px);
}