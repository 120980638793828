.floor {
  width: auto;
  margin-top: -20px;
}

.tabsContainer {
  margin-bottom: 20px;
}

.tabsContainer > div > span {
  background-color: #2A3842;
}

button.tabItem {
  border-bottom: 1px solid #2A384233;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  color: #2A3842;
  padding: 18px 16px 6px;
  width: 35%;

  &[aria-selected="true"] {
    font-weight: 600;
    color: #2A3842;
  }
}