.container {
  max-width: 531px;
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  color: #2A384280;

  .containerImg {
    padding-bottom: 10px;
    width: 100%;
    max-width: 358px;
  }
}
