.notificationTitle {
  font-size: 20px;
  text-transform: capitalize;
}

.notificationMessage {
  font-size: 16px;
  font-weight: 400;
  max-width: 450px;
}
