.filtersContainer {
  background: url('./bgFilters.png') no-repeat 50% / cover;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 14px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 900px) {
    border-radius: 32px;
    padding: 40px 60px 20px;
  }
}

.trialBannerContainer {
  background: url('./bgTrialBanner.png') no-repeat 50% / cover;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 10px 0 10px;
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 900px) {
    border-radius: 32px;
    padding: 10px 60px 10px;
  }
}

.discountBannerContainer {
  background: url('./bgDiscountBanner.png') no-repeat 50% / cover;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 10px 20px 10px;
  margin: 20px auto 0 auto;
  display: flex;

  @media (min-width: 900px) {
    border-radius: 32px;
    padding: 10px 40px;
  }
}

.row,
.rowControls {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.rowControls {
  //justify-content: flex-end;
  justify-content: space-between;
  margin: 18px 0 0;
  gap: 30px;
  align-items: center;
}

.addressAutocompleteStyle {
  flex-grow: 1;
}

.addressStyle {
  max-width: 676px;
  width: 100%;
}

div.filterSelect {
  border-radius: 14px;
  border: 1px solid #e9eef1;
  color: #2a3842;
  width: 100%;

  & > div[role='button'] {
    padding-left: 20px;
    font-size: 16px;
    text-transform: capitalize;
  }

  & > .filterSelectCaret {
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    pointer-events: none;

    &.filterCaretUp {
      transform: rotate(180deg);
    }

    &.filterCaretDown {
      transform: none;
    }
  }

  & > fieldset {
    border-color: transparent !important;
  }
}

.selectInnerContainer {
  margin: -8px 0;
  padding: 12px;

  &.filterActivityKind {
    max-width: 400px;
  }

  &.filterArea,
  &.filterPrice {
    max-width: 400px;
  }

  &.filterBeds {
    max-width: 500px;
  }

  p:first-child span.selectInnerTitle {
    margin-top: 0;
  }
}

.selectInnerContent {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  &.filterArea,
  &.filterPrice {
    flex-wrap: nowrap;

    & > div {
      width: 50%;
    }
  }
}

.frequencyContent {
  display: flex;
  background-color: #ffffff;
  flex-wrap: wrap;
  padding: 4px 0;
  border-radius: 14px;
  border: 1px solid #e9eef1;
  justify-content: center;
  gap: 3px;
}

.selectInnerTitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
}

.selectInnerLabel {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.buttonLink {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #1650ff;
  cursor: pointer;
}

.floor {
  width: auto;
  padding: 0 20px;
  margin-top: -10px;
}

.tabsContainer > div > span {
  background-color: #2a3842;
}

button.tabItem {
  border-bottom: 1px solid #2a384233;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #2a3842;
  padding: 18px 16px 6px;
  width: 50%;

  &[aria-selected='true'] {
    font-weight: 600;
    color: #2a3842;
  }
}

.buttonsContainer {
  display: flex;
  padding-top: 10px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

button.selectButtons {
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  text-transform: none;
  color: #2a3842;
  background: transparent;

  &:hover {
    background: #e9eef1;
    box-shadow: none;
  }

  &.selectButtonsActive {
    background: #1650ff;
    color: #ffffff;
  }

  &.activityKindButton {
    font-size: 16px;
  }

  &.propertyKindButton {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 10px;

    @media (min-width: 600px) {
      width: 190px;
    }
  }

  &.bedsBathsButton {
    min-width: 30px;
    padding: 6px 5px;

    @media (min-width: 600px) {
      min-width: 50px;
      padding: 6px 10px;
    }

    @media (min-width: 900px) {
      margin-right: 10px;
      min-width: 64px;
      padding: 6px 16px;
    }
  }
}

.activityKindContainer {
  max-width: 170px;
}

.propertyKindContainer {
  max-width: 215px;
}

.areaContainer {
  max-width: 180px;
}

.bedsBathsContainer {
  max-width: 190px;
}

.priceContainer {
  max-width: 140px;
}

.planControl {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 30px;
  margin-left: -15px;
}

.topUpLabel {
  display: flex;
  align-items: center;
  color: #2a3842;

  span {
    color: #1650ff;
    font-size: 16px;
    font-weight: 400;
  }
}

.checked {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>');
}


.itemButton {
  border-radius: 8px !important;
  box-shadow: none !important;
  min-width: auto !important;
  text-transform: none !important;
  color: #2a3842 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: auto 10px !important;

  background: transparent !important;

  &.active {
    background: #1650FF !important;
    color: #ffffff !important;
  }
}