.container {
  display: grid;
  gap: 12px 8px;
}


.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 15px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  
}

@media (max-width: 300px) {
  .row {
    width: 100%;
    max-width: 265px;
    grid-template-columns: 1fr
  }

  .myAutocomplete {
    max-width: 265px;
  }
}