.adsContainer {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.templateAdsContainer {
  display: flex;
  padding-top: 20px;
}

.listContainer {
  width: 100%;
}

.mapMode {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-items: center;
  gap: 20px;
  max-height: 70vh;
  overflow-x: scroll;
  flex: 1;
}

.adsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 31px;
  margin-top: 20px;
  justify-items: center;
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

// .templateMyAdsContainer {
//   padding-top: 20px;
// }

.tabsContainer {
  border-bottom: 1px solid #2a384220;
  padding-top: 24px;
}

.tabsInner > div > span {
  background-color: #2a3842;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2a384240;

  &[aria-selected='true'] {
    color: #2a3842;
  }
}

.myAdsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.content {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}
