.title {
  border-radius: 16px;
  background: url('./titleBg.png') no-repeat 50% / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.text {
  font-weight: 800;
  font-size: 28px;
  background: linear-gradient(90deg, #01439B -114.29%, #006AF8 71.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container {
  width: 100%;
}

.tabsContainer {
  border-bottom: 1px solid #2a384220;
  padding-top: 27px;
}

.tabsInner > div > span {
  background-color: #2a3842;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2a384240;

  &[aria-selected='true'] {
    color: #2a3842;
  }

  @media (min-width: 600px) {
    font-size: 20px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0 2px;
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}