.container {
  width: 100%;
  max-width: 1000px;
}

.dialogButtons {
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}
