.group {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.label {
  margin: 0 0 7px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  strong {
    color: #1650FF;
  }

  svg {
    cursor: pointer;
  }
}

.inputStyle {
  width: 100%;
}
