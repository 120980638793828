.videosContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 995px;
}

.container {
  flex-shrink: 0;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 16px 16px 0;
  cursor: pointer;
  position: relative;

  .buttons {
    visibility: hidden;
    display: flex;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &:hover {
    box-shadow: 3px 4px 9px 0 rgba(0, 0, 0, 0.14);

    .buttons {
      visibility: visible;
    }
  }
}

.delete {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: var(--main, linear-gradient(90deg, #01439b 0%, #006af8 100%));
  display: flex;
  align-items: center;
  justify-content: center;
}
