.inputRoot {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-right: 10px !important;
}

.gray {
  color: #959ba0;
  font-weight: 400;
}
.noOptions {
  display: none;
}