.container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.column {
  width: 280px;

  .blockTitle:first-child {
    padding-top: 0px;
  }
}

.blockTitle {
  width: 280px;
  font-size: 12px;
  font-weight: 600;
  color: #2A38427F;
  padding-top: 14px;
}
