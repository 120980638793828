.listItem {
  background-color: #f1f7ff;
  border-radius: 14px;
  transition: 0.3s;

  &:hover {
    .sliderControls {
      opacity: 1;
    }
  }

  @media (min-width: 600px) {
    border-radius: 10px;
  }
}

.itemImg {
  display: block;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 14px 14px 0 0;

    @media (min-width: 600px) {
      max-height: 350px;
    }
  }

  div.imgBottomCenterInfo {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 0 7px;
    z-index: 2;
    color: #fff;
    font-size: 0.8rem;
    width: 80px;
    text-align: center;
  }
}

.sliderControls {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  right: 18px;
  display: flex;
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
  }
}

.itemImgInfo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: end;
  padding: 18px;
}

.itemImgIcons {
  display: flex;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.itemImgIconsImages {
  display: flex;
  align-items: center;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;

  div.imgTotal {
    position: absolute;
    top: 0;
    left: -5px;
    right: -5px;
    bottom: 0;
    background-color: #111;
    opacity: 0.3;
    filter: brightness(150%) blur(4px);
    z-index: 1;
  }

  div.imgTotalNumber {
    position: relative;
    z-index: 2;
    color: #ddd;
    font-weight: 400;
  }
}

.locationIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #1650ff;
}

.linkOnMap {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  color: #1650FF;
  text-decoration: none;
  gap: 8px;

  span {
   color: #1650FF;
  }
}

.linkOnMap:visited {
  color: #1650FF;
}

@media (max-width: 600px) {
  .locationIcon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
  }
}

.list {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 20px;
}

.rentContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.rent {
  display: flex;
  width: 110px;
  height: 32px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.rentActive {
  border: 2px solid #1650FF;

  span {
    font-size: 14px;
    font-weight: 600;
    color:#1650FF;
   }
}

.rentPassive {
  border: 1px solid #2A3842;

  span {
    font-size: 14px;
    color:#2A3842;
   }
}

.itemFee {
  display: flex;
  align-items: center;
  padding: 7px 20px;
  border-radius: 30px;
  border: 1px solid #1650FF;
  gap: 10px;
  font-size: 16px;
  color: #1650ff;
}
