.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 30px;

  & > div {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  & > div > div {
    margin: 0;
  }

  @media (min-width: 600px) {
    & > div {
      max-width: 184px;
    }
  }
}


.dropzone {
  position: relative;
  width: 100%;
  max-width: 995px;
  height: 167px;
  border: 2px dashed var(--main, #01439b);
  border-radius: 4px;

  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 15px;
    margin: 0;

    span {
      color: rgba(42, 56, 66, 0.5);
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.pageName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;

  @media (max-width: 768px) { 
    align-items: flex-start;
    gap: 20px;
  }
}

.floor {
  width: auto;
  padding: 0 20px;
  margin-top: -10px;
}

.floorHeading {
  font-size: 15px;
  font-weight: 700;
  margin: 20px 0 0 0;
  display: block;
}

button.selectButtons {
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  text-transform: none;
  color: #2a3842;
  background: transparent;

  &:hover {
    background: #eeeeee;
    box-shadow: none;
  }

  &.selectButtonsActive {
    background: #c5dfff;
  }

  &.propertyKindButton {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 10px;

    @media (min-width: 600px) {
      width: 190px;
    }
  }

  &.bedsBathsButton {
    min-width: 30px;
    padding: 6px 5px;

    @media (min-width: 600px) {
      min-width: 50px;
      padding: 6px 10px;
    }

    @media (min-width: 900px) {
      margin-right: 10px;
      min-width: 64px;
      padding: 6px 16px;
    }
  }
}

.submitButtons {
  display: flex;
  margin-top: 40px;
  gap: 20px;
  flex-direction: column;

  @media (max-width: 600px) {
    button {
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.caretDown {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 15px;
  height: 16px;
  width: 16px;
}

.checked {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>');
}

.featuresContainer {
  min-width: 240px;
  max-width: 250px;
}

.label {
  margin: 0 0 7px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  span {
    color: #ff0000;
  }
}