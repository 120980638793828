.container {
  font-family: 'Poppins', sans-serif;
  // width: 315px;
  // height: 190px;
  flex-shrink: 0;
  background-size: cover;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  // margin: 0 16px 16px 0;
  cursor: pointer;

  .buttons {
    visibility: hidden;
    display: flex;
  }

  &:hover {
    box-shadow: 3px 4px 9px 0 rgba(0, 0, 0, 0.14);

    .buttons {
      visibility: visible;
    }
  }
}

.position {
  font-size: 16px;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileName {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  font-weight: 400;
  color: #000;
  
  .fileLinkHover {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    text-decoration: none;
    gap: '8px';
    color: #000;

    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}



.cover {
  width: 98px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.delete {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: var(--main, linear-gradient(90deg, #01439b 0%, #006af8 100%));
  display: flex;
  align-items: center;
  justify-content: center;
}

.open {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #fff;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: rgba(255, 255, 255, 0.14);
  }
}
