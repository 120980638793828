.priceTitle {
  font-size: 24px;
  font-weight: 600;
}

.tabsContainer {
  border-bottom: 1px solid #2a384220;
  padding-top: 24px;
}

.tabsInner > div > span {
  background-color: #2a3842;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2a384240;
  padding: 0 46px;

  &[aria-selected='true'] {
    color: #2a3842;
  }
}