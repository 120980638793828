.container {
  border: 1px solid #e9eef1;
  border-radius: 13px;
  display: flex;
  min-height: 96px;
  flex-direction: column;
  padding: 16px 0;

  @media (min-width: 600px) {
    flex-direction: row;
    padding: 0;
  }
}

.blockAvatar {
  border-right: 1px solid #e9eef1;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 12px;

  @media (min-width: 600px) {
    width: 450px;
    // flex: 1 0 320px;
  }
}

.avatarImage {
  width: 72px;
  height: 72px;
  border-radius: 50%;

  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.nameLabel {
  text-transform: capitalize;

  a:first-child {
    font-size: 16px;
    font-weight: 600;
    color: #2a3842;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    @media (min-width: 900px) {
      font-size: 18px;
    }
  }

  div:last-child {
    font-size: 16px;
    font-weight: 400;
    color: #2a384250;
  }
}

.blockDate {
  display: flex;
  flex-direction: row;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  align-items: center;

  @media (min-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    border-right: 1px solid #e9eef1;
    width: 200px;

  }
}

.dateLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #006af8;
}

.dateValue {
  color: #2a3842;
}

.blockButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 15px;
  flex-direction: column;


  @media (min-width: 600px) {
    max-width: 350px;
    padding: 15px;
  }

  @media (min-width: 900px) {
    flex-direction: row;
  }

  button {
    width: 100%;
    height: 48px;
    font-size: 15px;

    @media (min-width: 600px) {
      width: 136px;
      height: 40px;
    }
  }
}