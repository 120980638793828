.container {
  border-radius: 24px;
}

.label {
  // margin: 0 0 10px 0;
  color: var(--primary-dark-blue, #2a3842);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  strong {
    color: #1650FF;
  }

  svg {
    cursor: pointer;
  }
}

.inputStyle {
  width: 100%;
}

.group {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
  margin-bottom: 20px;
}

.locationGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}

.location {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.text {
  display: none;
  @media (min-width: 600px) {
    display: block;
  }

  color: rgba(42, 56, 66, 0.5);
  margin-top: 30px;
  flex-shrink: 0;

  & span {
    color: var(--primary-dark-blue, #2a3842);
    margin-left: 12px;
  }
}

.button {
  margin-top: 45px;

  button:hover svg {
    fill: none;
  }
}

.btnBack {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
  text-decoration: none;
  color: #1650FF;
  font-size: 16px;
  font-weight: 800;

  @media (max-width: 768px) { 
    align-items: flex-start;
    gap: 20px;
  }
}
