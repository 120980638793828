.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.submitButtons {
  display: flex;
  margin-top: 40px;
  gap: 20px;
  flex-direction: column;

  @media (max-width: 600px) {
    button {
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}
