.listPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 995px;
  margin: 30px auto 0;
  gap: 5px;
}

.rowsPerPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  gap: 15px;
}

.pageItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #2A3842;

  &:hover {
    background-color: #C5DFFF;
  }
}

.pageItemDots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.pageItemActive {
  background: linear-gradient(90deg, #01439B -114.29%, #006AF8 71.43%);
  color: #fff;
}

.pageItemDisabled {
  opacity: 0.5;
  pointer-events: none;
}  
