.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 15px;
  // margin-bottom: 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.buttonContainer {
  align-self: flex-start;
  margin-top: 10px;
}