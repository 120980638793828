.filtersContainer {
  background: url('./bgFilters.png') no-repeat 50% / cover;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 14px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 900px) {
    border-radius: 32px;
    padding: 40px;
  }
}

.row,
.rowControls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 12px;
}

.rowControls {
  justify-content: flex-end;
  margin: 18px 0 0;
}

.addressAutocompleteStyle {
  flex-grow: 1;
}

.addressStyle {
  max-width: 676px;
  width: 100%;
}

div.filterSelect {
  border-radius: 14px;
  border: 1px solid #e9eef1;
  color: #2a3842;
  // flex-grow: 1;
  // max-width: 200px;

  & > div[role='button'] {
    padding-left: 20px;
    font-size: 16px;
    text-transform: capitalize;
  }

  & > .filterSelectCaret {
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    pointer-events: none;

    &.filterCaretUp {
      transform: rotate(180deg);
    }

    &.filterCaretDown {
      transform: none;
    }
  }

  & > fieldset {
    border-color: transparent !important;
  }
}

.selectInnerContainer {
  margin: -8px 0;
  padding: 12px;

  &.filterActivityKind {
    max-width: 286px;
  }

  &.filterArea,
  &.filterPrice {
    max-width: 400px;
  }

  &.filterBeds {
    max-width: 480px;
  }

  p:first-child span.selectInnerTitle {
    margin-top: 0;
  }
}

.selectInnerContent {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;

  &.filterArea,
  &.filterPrice {
    flex-wrap: nowrap;

    & > div {
      width: 50%;
    }
  }
}

.selectInnerTitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
}

.selectInnerLabel {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.buttonLink {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #1650ff;
  cursor: pointer;
}

.floor {
  width: auto;
  padding: 0 20px;
  margin-top: -10px;
}

.tabsContainer > div > span {
  background-color: #2A3842;
}

button.tabItem {
  border-bottom: 1px solid #2A384233;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #2A3842;
  padding: 18px 16px 6px;
  width: 50%;

  &[aria-selected="true"] {
    font-weight: 600;
    color: #2A3842;
  }
}

.buttonsContainer {
  display: flex;
  padding-top: 10px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

button.selectButtons {
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  text-transform: none;
  color: #2a3842;
  background: transparent;

  &:hover {
    background: #eeeeee;
    box-shadow: none;
  }

  &.selectButtonsActive {
    background: #c5dfff;
  }

  &.activityKindButton {
    font-size: 16px;
  }

  &.propertyKindButton {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 10px;

    @media (min-width: 600px) {
      width: 190px;
    }
  }

  &.bedsBathsButton {
    min-width: 30px;
    padding: 6px 5px;

    @media (min-width: 600px) {
      min-width: 50px;
      padding: 6px 10px;
    }

    @media (min-width: 900px) {
      margin-right: 10px;
      min-width: 64px;
      padding: 6px 16px;
    }
  }
}

.activityKindContainer {
  max-width: 170px;
}

.propertyKindContainer {
  max-width: 215px;
}

.areaContainer {
  max-width: 180px;
}

.bedsBathsContainer {
  max-width: 190px;
}

.priceContainer {
  max-width: 140px;
}
