.fieldset fieldset {
  border-color: transparent !important;
}

.caretDown {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 15px;
  height: 16px;
  width: 16px;
}

.tabsContainer {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #2A384220;
  margin: -5px 0 10px;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2A384240;
  margin-right: 30px;

  &[aria-selected=true] {
    color: #2A3842;
  }
}

button.tabItem:last-child {
  margin-right: 0;
}
