.marker {
  background: #1650FF;
  height: 14px;
  width: 14px;
  border-radius: 20px;
  cursor: pointer;
  border: 2px white solid;
}

.markerInner {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background: #1650FF;
  position: absolute;
  top: 6px;
  left: 6px;
}

.markerBg {
  background: rgba(22, 80, 255, 0.2);
  position: relative;
  height: 22px;
  width: 22px;
  border-radius: 20px;
  cursor: pointer;
}

.userMarker {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: absolute;
  border: 2px solid white;
}