.container {
  display: flex;
  align-items: center;
  margin: 24px 0 10px;
  font-size: 18px;

  @media (min-width: 600px) {
    font-size: 24px;
    margin: 30px 0 20px;
  }
}

.title {
  color: var(--primary-dark-blue, #2a3842);

  font-style: normal;
  font-weight: 600;
  margin-left: 14px;
  span {
    color: var(--secondary, #f00);
  }
}

.number {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  border: 1px solid #e9eef1;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 600;
    background: var(--main, linear-gradient(90deg, #01439b 0%, #006af8 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
