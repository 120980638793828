.pswp__button--close,
.pswp__button--close:hover {
  border-radius: 14px;
  background: #fff;
  opacity: 1;
  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
}

.pswp__button--close svg,
.pswp__button--close svg:hover {
  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
}

.pswp .pswp__button,
.pswp__button,
.pswp__button--arrow svg {
  @media (min-width: 600px) {
    width: 64px;
    height: 64px;
  }
}
