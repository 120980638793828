.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.submitButtons {
  display: flex;
  margin-top: 40px;
  gap: 20px;
  flex-direction: column;

  @media (max-width: 600px) {
    button {
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.btnContainer {
  display: flex;
  flex-direction: column;
}

.btnBack {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  text-decoration: none;
  color: #2a3842;
  font-size: 16px;
  font-weight: 800;

  @media (max-width: 768px) { 
    align-items: flex-start;
    gap: 20px;
  }
}

.breadcrumbs {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
  gap: 20px;
  text-decoration: none;
  color: #2a3842;
  font-size: 16px;
  font-weight: 800;

  @media (max-width: 768px) { 
    align-items: flex-start;
    gap: 20px;
  }
}
