.uploader {
  position: relative;
  width: 100%;
  max-width: 995px;
  height: 217px;
  border-radius: 4px;
  border: 2px dashed var(--main, #01439b);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 15px;
    margin: 0;

    span {
      color: rgba(42, 56, 66, 0.5);
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.button {
  width: 217px;
  height: 44px;
  padding: 12px 51px 12px 50px;
  border-radius: 14px;
  background: var(
    --main,
    linear-gradient(90deg, #01439b -114.29%, #006af8 71.43%)
  );
  color: #fff !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
}

.text {
  margin: 20px 0 10px 0 !important;
}
