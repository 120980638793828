.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 995px;
}

.error {
  color: red;
  font-size: 16px;
}
