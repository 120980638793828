.mainContainer {
  width: 100%;
  max-width: 1000px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 20px;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
}