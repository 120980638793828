.listItem {
  background-color: #f1f7ff;
  border-radius: 14px;
  transition: 0.3s;

  &:hover {
    .sliderControls {
      opacity: 1;
    }
  }

  @media (min-width: 600px) {
    border-radius: 10px;
    max-width: 385px;
  }
}

.itemImg {
  display: block;
  position: relative;

  img {
    display: block;
    width: 100%;
    // max-height: 200px;
    object-fit: cover;
    border-radius: 14px 14px 0 0;

    @media (min-width: 600px) {
      max-height: 190px;
    }
  }
}

.itemWithoutImg {
  display: block;
  position: relative;
  border-radius: 14px 14px 0 0;
  background-color: #C5DFFF;

  img {
    display: block;
    width: 100%;
    // max-height: 200px;
    object-fit: cover;
    border-radius: 14px 14px 0 0;

    @media (min-width: 600px) {
      max-height: 190px;
    }
  }
}

.sliderControls {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  right: 18px;
  display: flex;
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
  }
}

.itemImgInfo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 18px;
}

.itemImgIcons {
  display: flex;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.itemImgIconsImages {
  display: flex;
  align-items: center;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
}

.locationIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #1650FF
}

@media (max-width: 600px) {
  .locationIcon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
  }
}

.list {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 20px;
  max-width: 385px;
}

.divider {
  margin: 5px 0;
}


.rentContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.rent {
  display: flex;
  width: 70px;
  height: 28px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.rentActive {
  border: 1px solid #1650FF;
  &:hover {
    border: 2px solid #1650FF;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color:#1650FF;
    &:hover {
      font-weight: 600;
    }
   }
}

.rentPassive {
  border: 1px solid #2A3842;
  &:hover {
    border: 2px solid #2A3842;
  }

  span {
    font-size: 12px;
    color:#2A3842;
    &:hover {
      font-weight: 600;
    }
   }
}

a.address {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.itemFee {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 30px;
  border: 1px solid #1650FF;
  gap: 10px;
  font-size: 14px;
  color: #1650ff;
}