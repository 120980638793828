.requestsContainer {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.templateRequestsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .templateRequestsContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}


.adsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 31px;
  margin-top: 20px;
  justify-items: center;

  @media (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
  }
}

.templateMyAdsContainer {
  padding-top: 20px;
}

.tabsContainer {
  border-bottom: 1px solid #2A384220;
  padding-top: 27px;
}

.tabsInner > div > span {
  background-color: #2A3842;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2A384240;

  &[aria-selected=true] {
    color: #2A3842;
  }
}

.myAdsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
