.keywordSearchContainer {
  background-color: #FFF;
  border-radius: 13px;
  box-shadow: none;
  width: 100%;
}

.input {
  color: rgba(0, 0, 0, 0.87);
  padding-left: 10px;
  width: 100%;
}

.iconButton {
  padding: 10px;
}
