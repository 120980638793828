.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 20px;

  & > div {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  & > div > div {
    margin: 0;
  }

  @media (min-width: 600px) {
    & > div {
      max-width: 230px;
    }
  }
}

.floor {
  width: auto;
  padding: 0 20px;
  margin-top: -10px;
}

.floorHeading {
  font-size: 15px;
  font-weight: 700;
  margin: 20px 0 0 0;
  display: block;
}

.buttonsContainer {
  display: flex;
  padding-top: 10px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

button.selectButtons {
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  text-transform: none;
  color: #2a3842;
  background: transparent;

  &:hover {
    background: #eeeeee;
    box-shadow: none;
  }

  &.selectButtonsActive {
    background: #c5dfff;
  }

  &.propertyKindButton {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 10px;

    @media (min-width: 600px) {
      width: 190px;
    }
  }

  &.bedsBathsButton {
    width: 100%;
    padding: 2px 2px;

    @media (min-width: 600px) {
      padding: 2px 2px;
    }
    @media (min-width: 900px) {
      margin-right: 2px;
      padding: 2px 2px;
    }
  }
}

.tabsContainer > div > span {
  background-color: #2a3842;
}

button.tabItem {
  border-bottom: 1px solid #2a384233;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #2a3842;
  padding: 18px 16px 6px;
  width: 50%;

  &[aria-selected='true'] {
    font-weight: 600;
    color: #2a3842;
  }
}
