.label {
  color: var(--primary-dark-blue, #2a3842);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  span {
    color: var(--secondary, #f00);
  }
}
