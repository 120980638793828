.container {
  max-width: 995px;
}

.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.currency {
  display: flex;
  gap: 12px 20px;
}

.price {
  grid-column: span 1;
}

@media (min-width: 600px) {
  .bgSeparator {
    border-left: 1px solid #E9EEF1;
    border-right: 1px solid #E9EEF1;
    padding: 0 22px;
  }
}

.topUpLabel {
  display: flex;
  align-items: center;
  color: #2A3842;

  span {
    color: #1650FF;
  }

  label {
    margin-right: 0;
  }
}
