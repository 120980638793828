.linkInputBlock {
  display: flex;
  gap: 13px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.linkInput {
  width: 100%;
}

.linkRefresh {
  padding-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #2a3842;

  span {
    margin-left: 12px;
    color: #006af8;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}
