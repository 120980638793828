.listItem {
  max-width: 995px;
  width: 100%;
  border-radius: 8px;
  background: #f1f7ff;
  display: flex;
  padding: 10px 16px;
  gap: 15px;
  flex-direction: column;
  position: relative;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
  }
}

.listItemImage {
  width: 100%;
  min-width: 88px;
  height: 133px;
  border-radius: 5px;
  background-color: #cbe3ff;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1000px) {
    width: 88px;
    height: 88px;
  }
}

.notLocation {
  width: 220px;
  height: 60px;
  background-color: #FF0000;
  color: #FFFFFF;
  border-radius: 15px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1000px) {
    width: 80px;
    height: 40px;
    font-size: 12px;
    border-radius: 5px;
  }
}

.menuIcon {
  margin-right: 5px;
}


a.title {
  // height: 56px;
  overflow: hidden;
  color: var(--primary-dark-blue, var(--Color-1, #2a3842));
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
}

.adsId {
  // width: 50px;
  flex-shrink: 0;
  text-align: right;
  color: rgba(42, 56, 66, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.addressBlock {
  min-width: 324px;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    padding: 0 20px 0 0;
    min-width: 324px;
    max-width: 324px;
  }
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemAddress {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemAddressBlack {
  color: #2a3842;
}

.itemAddressRed {
  color: #FF0000;
}

.statistic {
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.view,
.request {
  display: flex;
  color: var(--Color-1, #2a3842);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 150px;
  margin: 5px 0;

  span {
    margin-left: 5px;
    white-space: nowrap;
    color: #1650FF;
    font-weight: 600;
  }
}

.date,
.timer {
  display: flex;
  color: var(--Color-1, #2a3842);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 200px;
  margin: 5px 0;

  span {
    margin-left: 10px;
    white-space: nowrap;
    color: #1650FF;
  }
}

.timer {
  span {
    margin-left: 5px;
  }
}

.endingTimer {
  color: #FF0000;

  span {
    color: #FF0000;
  }
}

.currency {
  color: var(--Color-1, #2a3842);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #1650FF;
  }
}

.priceDateBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.frequency {
  width: 100px;
  height: 24px;
  border-radius: 14px;
  margin-left: 10px;
  border: 1px solid #1650FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfButtonSection {
  display: flex;
  gap: 9px;
}

a.itemName {
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #2a3842;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.itemCheckbox {
  @media (max-width: 1000px) {
    position: absolute;
    right: 17px;
    top: 12px;
  }
}

.checked {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>');
}

.dialogButtons {
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}