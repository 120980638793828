.container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.button {
  display: flex;
  height: 40px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 5px;
}

.buttonLarge {
  width: 190px;
}

.buttonBig {
  width: 154px;
}

.buttonMiddle {
  width: 117px;
}

.buttonSmall {
  width: 94px;
}

.buttonLittle {
  width: 74px;
}

.buttonCircle {
  width: 60px;
}

.buttonActive {
  border: 2px solid #1650FF;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 600;
    color:#1650FF;
   }
}

.buttonPassive {
  border: 1px solid rgba(42, 56, 66, 0.4);
  cursor: pointer;

  span {
    font-size: 14px;
    color:#2A3842;
   }
}

.buttonError {
  border: 1px solid red;
  cursor: pointer;

  span {
    font-size: 14px;
    color:#2A3842;
   }
}

.buttonDisable {
  background-color: rgba(42, 56, 66, 0.2);

  span {
    font-size: 14px;
    font-weight: 400;
    color:#2A3842;
   }
}