.tabsContainer {
  border-bottom: 1px solid #2A384220;
  // padding-top: 27px;
}

.tabsInner > div > span {
  background-color: #2A3842;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2A384240;

  &[aria-selected=true] {
    color: #2A3842;
  }
}

.submitButtons {
  display: flex;
  margin-top: 40px;
  gap: 20px;
  flex-direction: column;

  @media (max-width: 600px) {
    button {
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}