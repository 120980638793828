.listItem {
  max-width: 492px;
  width: 100%;
  border-radius: 14px;
  display: flex;
  padding: 15px 15px;
  gap: 10px;
  flex-direction: column;
  background: #F1F7FF;
  color: #2A3842;

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.list {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 20px;
  max-width: 385px;
}


.listItemImage {
  width: 100%;
  min-width: 200px;
  height: 130px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listItemImageForMe {
  @media (min-width: 900px) {
    width: 208px;
    height: 130px;
  }
}

.listItemImageByMe {
  @media (min-width: 900px) {
    width: 200px;
    height: 160px;
  }
}

.unreadCount {
  background-color: #1650FF;
  width: 52px;
  height: 40px;
  color: #FFFFFF;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.menuIcon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

a.title {
  height: 56px;
  overflow: hidden;
  color: var(--primary-dark-blue, var(--Color-1, #2a3842));
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

a.link {
  text-decoration: none;
}

.adsId {
  width: 70px;
  flex-shrink: 0;
  text-align: right;
  color: rgba(42, 56, 66, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.addressBlock {
  max-width: 360px;
  min-width: 360px;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    max-width: 320px;
    min-width: 320px;
    padding: 0 10px;
  }
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.itemAddress {  
  display: 'flex';
  align-items: 'flex-start';
  justify-content: 'flex-start';
  flex-direction: row;

    @media (min-width: 900px) {
    max-width: 300px;
  }
}

.date,
.timer {
  display: flex;
  color: var(--Color-1, #2a3842);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 200px;
  margin: 5px 0;

  span {
    margin-left: 10px;
    white-space: nowrap;
    color: #1650FF;
  }
}

.timer {
  span {
    margin-left: 5px;
  }
}

.endingTimer {
  color: #FF0000;

  span {
    color: #FF0000;
  }
}

.currency {
  color: var(--Color-1, #2a3842);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #1650FF;
  }
}

.priceDateBlock {
  max-width: 360px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
}

.itemParams {
  display: flex;
  gap: 12px;
  font-size: 15px;
  margin-bottom: 10px;

  @media (min-width: 600px) {
    gap: 15px;
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 8px;
  }
}

.itemParamsSeparator {
  width: 1px;
  height: 24px;
  background-color: #2a384233;
}

.requests {
  color: var(--Color-1, #2a3842);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  white-space: nowrap;
  overflow: hidden;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #1650FF;
  }
}

.itemLinks {
  max-width: 360px;
  min-width: 360px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    color: #1650ff;
    width: 100%;
    overflow: hidden;

    div {
      width: 24px;
      height: 24px;
      font-size: 12px;
      font-weight: 500;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
  p {
    font-size: 14px;
    color: #2a3842;
    margin-left: 35px;
    margin-top: 0;
    opacity: 0.5;
  }
}

.itemLinksWithoutEmployer {

  span {
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}

.itemLinksWithEmployer {
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}

.connect {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 10px;

  @media (min-width: 900px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;
  flex-direction: column;

  button {
    height: 56px;
  }
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
  }
}