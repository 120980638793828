.listItem {
  background-color: #f1f7ff;
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    .sliderControls {
      opacity: 1;
    }
  }

  @media (min-width: 600px) {
    border-radius: 20px;
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  // max-width: 995px;
}

.itemImg {
  display: block;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 14px 14px 0 0;

    @media (min-width: 600px) {
      max-height: 350px;
    }
  }

  div.imgBottomCenterInfo {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 0 7px;
    z-index: 2;
    color: #fff;
    font-size: 0.8rem;
    width: 80px;
    text-align: center;
  }
}

.planImg {
  display: block;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    width: 137px;
    height: auto;
    object-fit: cover;

    // @media (min-width: 600px) {
    //   max-height: 350px;
    // }
  }
}

.sliderControls {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  right: 18px;
  display: flex;
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
  }
}

.itemImgInfo {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 18px;
}


.itemImgIcons {
  display: flex;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.itemImgIconsImages {
  display: flex;
  align-items: center;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;

  div.imgTotal {
    position: absolute;
    top: 0;
    left: -5px;
    right: -5px;
    bottom: 0;
    background-color: #111;
    opacity: 0.3;
    filter: brightness(150%) blur(4px);
    z-index: 1;
  }

  div.imgTotalNumber {
    position: relative;
    z-index: 2;
    color: #ddd;
    font-weight: 400;
  }
}

.locationIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #1650ff;
}

a.linkOnMap {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  color: #1650FF;
  text-decoration: none;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
}

.linkOnMap:visited {
  color: #1650FF;
}

@media (max-width: 600px) {
  .locationIcon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
  }
}

.list {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 20px;
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}
