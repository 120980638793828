@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.header {
  border-radius: 16px;
  background:
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.3) 100%
    ),
    url('./bg_small.png') center center / cover no-repeat,
    lightgray 0px -484px / 121.927% 752.727% no-repeat;
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    height: 110px;
    border-radius: 32px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    background: var(--main, linear-gradient(90deg, #01439b 0%, #006af8 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 600px) {
      font-size: 28px;
    }
  }
}
