.label {
  margin: 0 0 7px 0;
  color: #2a3842;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  
  span {
    color: #ff0000;
  }
}

.fieldset fieldset {
  border-color: transparent !important;
}

.caretDown {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 15px;
  height: 16px;
  width: 16px;
}
