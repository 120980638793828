.label {
  margin: 0 0 7px 0;
  color: #2a3842;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  span {
    color: var(--secondary, #f00);
  }
}

.labelWeight {
  margin: 0 0 7px 0;
  color: #2a3842;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  span {
    color: var(--secondary, #f00);
  }
}
