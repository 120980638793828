.label {
  color: var(--primary-dark-blue, #2a3842);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
}

.fieldset {
  border-color: transparent !important;
  height: 48px;
}

.closeIcon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8.88173 7.9864L13.8557 3.0257C13.954 2.91123 14.0054 2.76398 13.9996 2.61338C13.9937 2.46278 13.9311 2.31992 13.8243 2.21335C13.7174 2.10677 13.5742 2.04434 13.4232 2.03852C13.2722 2.03271 13.1245 2.08393 13.0097 2.18197L8.03573 7.14266L3.06173 2.17598C2.94874 2.0633 2.79551 2 2.63573 2C2.47595 2 2.32271 2.0633 2.20973 2.17598C2.09674 2.28866 2.03327 2.44149 2.03327 2.60084C2.03327 2.7602 2.09674 2.91302 2.20973 3.0257L7.18973 7.9864L2.20973 12.9471C2.14692 13.0007 2.0959 13.0668 2.05989 13.141C2.02388 13.2152 2.00364 13.2961 2.00045 13.3785C1.99726 13.4609 2.01118 13.5431 2.04134 13.6199C2.07151 13.6967 2.11726 13.7664 2.17574 13.8247C2.23421 13.8831 2.30414 13.9287 2.38113 13.9588C2.45813 13.9889 2.54053 14.0027 2.62316 13.9996C2.70579 13.9964 2.78687 13.9762 2.86131 13.9403C2.93575 13.9044 3.00194 13.8535 3.05573 13.7908L8.03573 8.83014L13.0097 13.7908C13.1245 13.8889 13.2722 13.9401 13.4232 13.9343C13.5742 13.9285 13.7174 13.866 13.8243 13.7595C13.9311 13.6529 13.9937 13.51 13.9996 13.3594C14.0054 13.2088 13.954 13.0616 13.8557 12.9471L8.88173 7.9864Z" fill="%232A3842"/></svg>');
  display: block;
  height: 16px;
  width: 16px;
  margin-right: -20px !important;
  margin-left: 4px !important;
}

input[type=checkbox] + span {
  border-radius: 7px;
  width: 32px;
  height: 32px;
  border: 2px solid #E9EEF1;
}

.checkedIcon {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>');
}

.caretDown {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 15px;
  height: 16px;
  width: 16px;
}
