.container {
  max-width: 570px;
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;

  .containerImg {
    width: 100%;
    max-width: 537px;
  }
}

.description {
  max-width: 488px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 30px;
  color: #2a384280;
}
