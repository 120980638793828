.itemButton {
  border-radius: 4px !important;
  box-shadow: none !important;
  min-width: auto !important;
  text-transform: none !important;
  color: #2a3842 !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;

  background: transparent !important;

  &.active {
    background: #c5dfff !important;
  }
}
