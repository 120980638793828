@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.container {
    border-radius: 24px;
}

.inputStyle {
    font-family: 'Poppins', sans-serif !important;
    width: 310px;
}

.group {
    display: flex;
    align-items: center;
}

.text {
    color: rgba(42, 56, 66, 0.50);
    margin-top: 16px;
    & span {
        color: var(--primary-dark-blue, #2A3842);
        margin-left: 12px;
    }
}