.filtersContainer {
  border-radius: 12px;
  box-sizing: border-box;
  padding: 14px;
  margin: 0 auto;

  @media (min-width: 900px) {
    border-radius: 32px;
    padding: 40px 60px 20px;
  }
}

.row,
.rowControls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 12px;
}

.rowControls {
  justify-content: space-between;
  margin: 18px 0 0;
  gap: 30px;
  align-items: center;
}

.addressAutocompleteStyle {
  flex-grow: 1;
}

.addressStyle {
  max-width: 676px;
  width: 100%;
}

div.filterSelect {
  border-radius: 14px;
  border: 1px solid #e9eef1;
  color: #2a3842;
  flex-grow: 1;
  max-width: 200px;
  
  & > div[role='button'] {
    padding-left: 20px;
    font-size: 16px;
    text-transform: capitalize;
  }
  
  & > fieldset {
    border-color: transparent !important;
  }
}

.selectInnerContainer {
  width: 380px;
  margin: -8px 0;
  padding: 12px;
}

.selectInnerContent {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.selectInnerTitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
}

.buttonLink {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #1650ff;
  cursor: pointer;
}

.floor {
  width: auto;
  padding: 0 20px;
  margin-top: -10px;
}

button.tabItem {
  border-bottom: 1px solid #2A3842;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #2A3842;
  padding: 18px 16px 6px;
  width: 50%;

  &[aria-selected="true"] {
    font-weight: 600;
    color: #2A3842;
  }
}

.buttonsContainer {
  display: flex;
  padding-top: 10px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

button.selectButtons {
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  text-transform: none;
  color: #2a3842;
  background: transparent;

  &:hover {
    background: #eeeeee;
    box-shadow: none;
  }

  &.selectButtonsActive {
    background: #c5dfff;
  }
}

.planControl,
.activityKindContainer,
.propertyKindContainer,
.areaContainer,
.bedsBathsContainer,
.priceContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 30px;
}

.topUpLabel {
  display: flex;
  align-items: center;
  color: #2A3842;

  span {
    color: #1650FF;
    font-size: 16px;
    font-weight: 400;
  }
}

.checked {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,...');
}

.caretDown {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 15px;
  height: 16px;
  width: 16px;
}
