@import url('https://fonts.googleapis.com/css?family=Poppins');

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #f9fcff;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
