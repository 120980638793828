.container {
  display: grid;
  // gap: 12px 8px;
}


.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 15px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.inputWithButton {
  display: grid;
  grid-template-columns: 1fr auto; 
}

.inputWithoutButton {
  display: grid;
  grid-template-columns: 1fr;
}

@media (max-width: 300px) {
  .inputWithButton {
    width: 100%;
    max-width: 250px;
    grid-template-columns: 1fr auto;
  }

  .inputWithButton .myPhoneInput {
    max-width: 220px;
  }
}

.linkWithButton {
  display: grid;
  grid-template-columns: 1fr auto; 
  gap: 5px; 
}


.linkWithoutButton {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px 0;
}