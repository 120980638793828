.container {
  width: 100%;
  border-radius: 32px;
  background: #f1f7ff;
  display: flex;
  padding: 32px;
  gap: 20px;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: center;
  }
}