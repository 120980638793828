.container {
  width: 100%;
  max-width: 1000px;

  // @media (min-width: 600px) {
  //   width: 90%;
  // }
}


.mainInfoContainer {
  border-radius: 12px;
  box-sizing: border-box;
  padding: 14px;

  @media (min-width: 900px) {
    border-radius: 32px;
    padding: 24px;
    height: 140px;
  }
  
  @media (min-width: 600px) {
    border-radius: 32px;
    padding: 24px;
    height: 140px;
  }
}

.mainInfoViewContainer {
  background: url('./plashka.svg') no-repeat 50% / cover;
}

.mainInfoEditContainer {
  background: linear-gradient(to bottom, transparent 49%, rgba(0, 0, 0, 0.5) 50%), url('./plashka.svg') no-repeat 50% / cover;
}


.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.viewRow {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}




.rowControls {
  display: flex;
  justify-content: space-between;
}

.rowControls {
  justify-content: flex-end;
  margin: 18px 0 0;
}

.addressAutocompleteStyle {
  flex-grow: 1;
}

.addressStyle {
  max-width: 676px;
  width: 100%;
}

div.filterSelect {
  border-radius: 14px;
  border: 1px solid #e9eef1;
  color: #2a3842;
  flex-grow: 1;
  max-width: 200px;



  & > div[role='button'] {
    padding-left: 20px;
    font-size: 16px;
    text-transform: capitalize;
  }

  & > .filterSelectCaret {
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    pointer-events: none;

    &.filterCaretUp {
      transform: rotate(180deg);
    }

    &.filterCaretDown {
      transform: none;
    }
  }

  & > fieldset {
    border-color: transparent !important;
  }
}

.selectInnerContainer {
  margin: -8px 0;
  padding: 12px;

  &.filterActivityKind {
    max-width: 286px;
  }

  &.filterArea,
  &.filterPrice {
    max-width: 400px;
  }

  &.filterBeds {
    max-width: 475px;
  }

  p:first-child span.selectInnerTitle {
    margin-top: 0;
  }
}

.selectInnerContent {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  &.filterArea,
  &.filterPrice {
    flex-wrap: nowrap;

    & > div {
      width: 50%;
    }
  }
}

.selectInnerTitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
}

.selectInnerLabel {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.buttonLink {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #1650ff;
  cursor: pointer;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
  background: white;
  padding: 40px 100px;
  border-radius: 20px;
  align-items: center;
  gap: 24px;
  position: relative;

  img {
    width: 374px;
    height: 298px;
  }
}

.closeIcon {
  position: absolute;
  right: 40px;
  cursor: pointer;
  top: 32px
}