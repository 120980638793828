.container {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1 1 calc(33.333% - 20px);
  margin: 10px;
}

.blockTitle {
  width: 280px;
  font-size: 12px;
  font-weight: 600;
  color: #2A38427F;
  padding-top: 14px;
}
