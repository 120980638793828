.title {
  border-radius: 16px;
  background: url('./titleBg.png') no-repeat 50% / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.text {
  font-weight: 800;
  font-size: 28px;
  background: linear-gradient(90deg, #01439B -114.29%, #006AF8 71.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container {
  width: 100%;
}


.adsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 31px;
  margin-top: 20px;
  justify-items: center;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
}