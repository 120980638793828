.listItem {
  width: 100%;
  border-radius: 20px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  // margin-bottom: 20px;
  gap: 10px;
  color: #2A3842;

  @media (min-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.listItemUnread {
  background: #C5DFFF;
}

.listItemRead {
  background: #F1F7FF;
}


.menuIcon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.dateContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.dateBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;

  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.date {
  display: flex;
  align-items: center;
  color: var(--Color-1, #2a3842);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 180px;

  span {
    color: #2a3842;
    opacity: 0.7;
  }
}

.time {
  display: flex;
  align-items: center;
  color: var(--Color-1, #2a3842);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 120px;

  span {
    color: #2a3842;
    opacity: 0.7;
  }
}

.itemLinks {
  // max-width: 360px;
  // min-width: 360px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  a {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    gap: 10px;
    color: #2a3842;
    width: 100%;
    overflow: hidden;

    div {
      width: 40px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
    }

    span {
      // max-width: 250px;
      // min-width: 250px;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
  p {
    font-size: 14px;
    color: #2a3842;
    margin-left: 50px;
    margin-top: -10px;
    opacity: 0.5;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;
  }
}

a.itemName {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

a.chanelText {
  text-decoration: none; 
  color: #1650FF;
  &:hover {
    text-decoration: underline;
  }
}
