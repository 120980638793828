.listControls {
  display: flex;
  justify-content: flex-end;
  max-width: 995px;
}

.sortControl {
  display: flex;
  align-items: center;
  gap: 20px;
}

div.sortSelect {
  border-radius: 14px;
  border: 1px solid #E9EEF1;
  min-width: 200px;

  & > div[role= button] {
    padding-left: 30px;
    font-size: 16px;
    text-transform: capitalize;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 7px;
      top: 13px;
    }
  }

  &.sortOrderAsc div[role= button]:before{
    background: url("./sortUp.svg") no-repeat;
  }

  &.sortOrderDesc div[role= button]:before{
    background: url("./sortDown.svg") no-repeat;
  }

  & > .sortSelectCaret {
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    pointer-events: none;

    &.sortCaretUp{
      transform: rotate(180deg);
    }

    &.sortCaretDown{
      transform: none;
    }
  }
  
  & > fieldset {
    border-color: transparent!important;
  }
}

li.sortSelectItem {
  font-family: Poppins, sans-serif;
  // text-transform: capitalize;
  padding-left: 35px;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 7px;
    top: 7px;
  }

  &.sortItemOrderAsc:before{
    background: url("./sortUp.svg") no-repeat;
  }

  &.sortItemOrderDesc:before{
    background: url("./sortDown.svg") no-repeat;
  }
}

.viewModeControl {
  display: flex;
  align-items: center;
  gap: 7px;
  text-transform: capitalize;
  border-radius: 32px;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background-color: #C5DFFF;
  }
}
