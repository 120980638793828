.rowTitle {
  margin-top: 15px;
}

.rowDescription {
  margin-top: 30px;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.labelTitle {
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-dark-blue, #2A3842);

  &:after {
    content: '*';
    color: var(--secondary, #f00);
  }
}
