.label {
  margin: 0 0 7px 0;
  color: var(--primary-dark-blue, #2A3842);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  span {
    color: #FF0000;
  }
}

.fieldset fieldset {
  border-color: transparent !important;
}

.caretDown {
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 15px;
  height: 16px;
  width: 16px;
}
