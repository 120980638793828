.container {
  border-bottom: 1px solid #e9eef1;
  display: flex;
  min-height: 72px;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    padding: 10px 15px;
  }
}

.myContainer {
  display: flex;
  min-height: 96px;
  justify-content: left;
  padding: 16px 0;
  align-items: center;

  @media (min-width: 600px) {
    justify-content: space-between;
    padding: 0;
  }
}

.blockAvatar {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 12px;

  @media (min-width: 600px) {
    width: 490px;
    // flex: 1 0 500px;
  }
}

.blockMyAvatar {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 12px;
}

.avatarImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.nameLabel {
  text-transform: capitalize;

  a:first-child {
    font-size: 16px;
    font-weight: 600;
    color: #2a3842;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    @media (min-width: 600px) {
      font-size: 18px;
    }
  }

  div:last-child {
    font-size: 16px;
    font-weight: 400;
    color: #2a384250;
  }
}

.blockAdData {
  border-right: 1px solid #e9eef1;
  box-sizing: border-box;
  display: flex;
  width: 340px;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  flex-direction: row;

  @media (min-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.adData {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    color: #006af8;
  }
}

.blockButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 15px;


  @media (min-width: 600px) {
    max-width: 180px;
  }

  button {
    width: 100%;
    height: 40px;
    font-size: 15px;
    background: #ffffff;
    color: #1650ff;
    border: 2px solid #1650ff;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    border-radius: 14px;
    font-weight: 500;
    display: flex;
    gap: 8px;

    @media (min-width: 600px) {
      width: 136px;
    }

    &:hover {
      border: 1px solid #1650ff;
      box-shadow: 0 0 7px 2px rgba(1, 92, 213, 0.6);
      background: #ffffff;
    }
  }
}

.indicator {
  width: 83px;
  height: 12px;
  border-radius: 20px;
  border: 1px solid #1650FF;
  background-color: #FFFFFF;
}

.indicatorFull {
  width: 83px;
  height: 12px;
  border-radius: 20px;
  background-color: #1650FF;
}
