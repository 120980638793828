.container {
  max-width: 995px;
  background-color: #F1F7FF;
  border: 1px solid #e9eef1;
  border-radius: 13px;
  display: flex;
  min-height: 96px;
  flex-direction: column;
  padding: 16px;

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
}

.blockAvatar {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 12px;

  @media (min-width: 600px) {
    width: 500px;
    // flex: 1 0 500px;
  }
}

.avatarImage {
  width: 72px;
  height: 72px;
  border-radius: 50%;

  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.nameLabel {
  text-transform: capitalize;

  a:first-child {
    font-size: 16px;
    font-weight: 600;
    color: #2a3842;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }

    @media (min-width: 600px) {
      font-size: 18px;
    }
  }

  div:last-child {
    font-size: 16px;
    font-weight: 400;
    color: #2a384250;
  }
}

.blockAdData {
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
  }
  @media (min-width: 900px) {
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    width: 250px;
  }
}

.adData {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    color: #006af8;
  }
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0 10px;
}
