.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 15px;
  color: #2A3842;

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.requestsContainer {
    max-width: 610px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    @media (min-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
}

.adContainer {
  max-width: 385px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.pageName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // margin-bottom: 20px;
  gap: 5px;

  @media (max-width: 768px) { 
    align-items: flex-start;
    gap: 20px;
  }
}

.search {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
}

