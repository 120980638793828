.templateMyAdsContainer {
  min-height: 570px;
  padding-top: 20px;
}

.tabsContainer {
  border-bottom: 1px solid #2A384220;
  padding-top: 27px;
}

.tabsInner > div > span {
  background-color: #2A3842;
}

button.tabItem {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2A384240;

  &[aria-selected=true] {
    color: #2A3842;
  }
}

.adsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 31px;
  margin-top: 20px;
  justify-items: center;

  @media (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
  }
}



.usersContainer {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  margin-bottom: 14px;
  border: 1px solid transparent;
  background: linear-gradient(to right, #f9fcff, #f9fcff),
    linear-gradient(90deg, #01439b 0%, #006af8 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  // color: var(--primary-dark-blue, #2a3842);
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  overflow: hidden;
  padding: 10px 0;

  @media (min-width: 600px) {
    width: 235px;
    min-width: 235px;
  }
}

.image {
  display: flex;
  position: relative;

  & img {
    height: 64px;
    width: 64px;
    border-radius: 64px;
    object-fit: cover;
  }

  & span {
    height: 64px;
    width: 64px;
    border-radius: 64px;
    text-align: center;
    background: #242526;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #006af8;
    font-size: 30px;
    font-weight: bold;
  }

  div {
    position: absolute;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    border: 2px solid #f9fcff;
    right: 4px;
    top: 2px;
  }
}

.name {
  font-size: 16px;
  font-weight: 600;
  padding: 0 7px;
  box-sizing: border-box;
  text-align: center;
  color: #2A3842;
}

.title {
  font-size: 16px;
  font-weight: 400;
  color: #2A3842;
  opacity: 0.5;
}