.container {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.title {
  border-radius: 16px;
  background: url('./titleBg.png') no-repeat 50% / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.text {
  font-weight: 800;
  font-size: 28px;
  background: linear-gradient(90deg, #01439B -114.29%, #006AF8 71.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rateContainer {
  background: url('./rateBg.png') no-repeat 50% / cover;
  display: flex;
  justify-content: center;
  align-items: left;
  margin: 20px 0;
  border-radius: 16px;
  border: 1px solid #E9EEF1;
  padding: 20px;
}

.rate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #E9EEF1;
  padding: 20px;
}

.codeContainer {
  background: url('./promoCodeBg.png') no-repeat 50% / cover;
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin: 20px 0 50px;
  border-radius: 16px;
  border: 1px solid #E9EEF1;
  padding: 20px 40px;
}

.inviteContainer {
  background: url('./inviteBg.png') no-repeat 50% / cover;
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin: 20px 0;
  border-radius: 16px;
  border: 1px solid #E9EEF1;
  padding: 10px 30px 10px 15px;
}


.list {
  display: flex;
  flex-direction: column;
  // padding: 24px 0 2px;
  border: 1px solid #e9eef1;
  border-radius: 13px;
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}

.listItem {
  max-width: 995px;
  width: 100%;
  border-radius: 8px;
  background: #f1f7ff;
  display: flex;
  padding: 10px 16px;
  gap: 15px;
  flex-direction: column;
  position: relative;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
  }
}

.itemCheckbox {
  @media (max-width: 1000px) {
    position: absolute;
    right: 17px;
    top: 12px;
  }
}

.checked {
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: 50%;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_438_20446)"><path d="M6.14749 15.3682L0.369714 9.59039C0.0225955 9.24328 0.0225955 8.68046 0.369714 8.33331L1.62676 7.07623C1.97388 6.72907 2.53673 6.72907 2.88385 7.07623L6.77603 10.9684L15.1127 2.63178C15.4598 2.28467 16.0226 2.28467 16.3697 2.63178L17.6268 3.88887C17.9739 4.23598 17.9739 4.7988 17.6268 5.14595L7.40457 15.3682C7.05742 15.7153 6.49461 15.7153 6.14749 15.3682Z" fill="url(%23paint0_linear_438_20446)"/></g><defs><linearGradient id="paint0_linear_438_20446" x1="-20.2081" y1="8.99999" x2="12.8078" y2="8.99999" gradientUnits="userSpaceOnUse"><stop stop-color="%2301439B"/><stop offset="1" stop-color="%23006AF8"/></linearGradient><clipPath id="clip0_438_20446"><rect width="17.7778" height="17.7778" fill="white" transform="translate(0.109375 0.111115)"/></clipPath></defs></svg>');
}