.listItem {
  width: 100%;
  max-width: 370px;

  background-color: #f1f7ff;
  border: 1px solid #E9EEF1;
  border-radius: 14px;
  transition: 0.3s;

  &:hover {
    box-shadow: 2px 1px 16px 0 #00000024;

    .itemUpdatedDate {
      opacity: 1;
    }
  }

  @media (min-width: 600px) {
    max-width: 482px;
  }
}

.itemImg {
  display: block;
  position: relative;

  img {
    display: block;
    height: 258px;
    width: 100%;
    border-radius: 14px 14px 0 0;
    object-fit: cover;
  }
}

.imageWrapper {
  overflow: hidden;
  display: block;
  max-height: '100%';
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #000000, transparent);
  }
}

.itemImgInfo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  text-decoration: none;
}

a.itemImgInfoLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  text-decoration: none;
}

a.itemInfo {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

a.itemUpdatedDateLink {
  transition: 0.3s;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  height: 32px;
  border-radius: 10px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  color: #2A3842;
  text-decoration: none;

  img {
    width: 20px;
    height: 20px;
  }
}

.itemUpdatedDate {
  transition: 0.3s;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  height: 32px;
  border-radius: 10px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  color: #2A3842;
  text-decoration: none;

  img {
    width: 20px;
    height: 20px;
  }
}

.itemImgIcons {
  display: flex;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
}

.itemContent {
  padding: 14px;
  max-width: 400px;

  @media (min-width: 900px) {
    padding: 16px 22px;
    max-width: 480px;
  }
}



.itemName {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (min-width: 900px) {
    font-size: 24px;
  }
}

.itemLabel {
  display: inline-block;
  border: 1px solid #1650ff;
  border-radius: 35px;
  padding: 3px 13px;
  font-size: 12px;
  font-weight: 600;
  color: #1650ff;
  text-transform: capitalize;
}

.itemFee {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  color: #1650ff;
}

.itemAddress {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-weight: 500;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;

  @media (min-width: 900px) {
    font-size: 16px;
  }
}

.itemLinks {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #2a384220;
  margin-top: 20px;
  padding-top: 10px;
  gap: 15px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    color: #1650ff;
    width: 50%;
    overflow: hidden;

    div {
      font-family: 'Poppins', sans-serif;
      width: 24px;
      height: 24px;
      font-size: 12px;
      font-weight: 500;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.unitContent {
  display: flex;
  padding: 14px;
  max-width: 400px;
  min-height: 68px;
  direction: row;
  gap: 10px;

  @media (min-width: 900px) {
    padding: 12px 22px;
    max-width: 480px;
    gap: 15px;
  }
}

.unitContentCenter {
  justify-content: center;
}

.itemParams {
  display: flex;
  gap: 12px;
  font-size: 15px;
  align-items: center;

  @media (min-width: 600px) {
    gap: 26px;
  }

  div {
    display: flex;
    align-items: center;
  }
}

.itemParamsWithoutUnits {
  display: flex;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
}

.itemParamsSeparator {
  width: 1px;
  background-color: #2a384233;
}