.row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.availRow {
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 12px 20px;
  }
}

.rowOnlySize {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.size {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 2;
  }
}

.onlySize {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 1;
  }
}


.view {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  grid-column: span 1;

  @media (min-width: 600px) {
    grid-column: span 3;
  }
}

.distressError {
  margin: 20px auto;
  width: 90%;
  gap: 12px 20px;
  border-radius: 10px;
  border: 2px solid red;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.buttonMobile {
  display: flex;
  height: 40px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 15px auto 0;
  border: 2px solid #1650FF;

  span {
    font-size: 14px;
    font-weight: 400;
    color:#1650FF;
   }

  &:hover {
    border: 'none';
    background-color: rgba(197, 223, 255, 0.5);

    span {
      font-size: 14px;
      font-weight: 600;
      color:#1650FF;
     }
  };


}

.button {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px auto 0;
  gap: 10px;
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: 6400;
    color:#1650FF;
   }
  &:hover {
    span {
      font-weight: 600;
      }
  };
}
