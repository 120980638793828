.agencyFieldContaier {
  margin: 80px 0 0;
}

.sectionName {
  font-size: 28px;
  font-weight: 600;
  color: #2a3842;
  padding-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 20px;
    padding-bottom: 15px;
  }
}

.agenciesInput input[type='text'] {
  height: 15px;
  font-size: 16px;
}

.agenciesInput > p {
  font-size: 14px;
  padding-left: 15px;
}

.agenciesAutocomplete {
  width: 512px;

  @media (max-width: 600px) {
    width: 370px;
  }

  @media (max-width: 300px) {
    max-width: 265px;
  }
}

.agenciesAutocompletePending > div > div {
  background-color: #ffffff !important;

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('./pendingIcon.png');
    opacity: 0.4;
  }
}

.dialogDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #2a3842;
  text-align: center;
}

.dialogButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 56px;
  }
}
